var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"user-wrap"},[_vm._m(0),_c('div',{staticClass:"main-wrap"},[_vm._m(1),_c('div',{staticClass:"main-right"},[_c('div',{staticClass:"mr-wrap"},[_c('div',{staticClass:"right-item itemold"},[_vm._m(2),_vm._m(3),_c('p',{staticClass:"vertifiy"},[_c('span',{staticClass:"vertifiyspan",on:{"click":_vm.navauth}},[_vm._v("去认证")])])]),_vm._m(4),_vm._m(5)])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bread-wrap"},[_c('i',{staticClass:"iconfont icon-shouji"}),_c('span',{staticClass:"bread-title"},[_vm._v("个人中心")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"main-left"},[_c('div',{staticClass:"titleaccount"},[_c('i',{staticClass:"iconfont icon-gerenzhongxin"}),_c('span',{staticClass:"account"},[_vm._v("195****1432")])]),_c('div',{staticClass:"accounttext"},[_c('span',{staticClass:"accountlabel"},[_vm._v("账号类型：")]),_c('span',{staticClass:"content"},[_vm._v("主账号")])]),_c('div',{staticClass:"accounttext"},[_c('span',{staticClass:"accountlabel"},[_vm._v("账号ID：")]),_c('span',{staticClass:"content"},[_vm._v("主账号")])]),_c('div',{staticClass:"accounttext"},[_c('span',{staticClass:"accountlabel"},[_vm._v("昵称：")]),_c('span',{staticClass:"content"},[_vm._v("---")])]),_c('div',{staticClass:"accounttext"},[_c('span',{staticClass:"accountlabel"},[_vm._v("性别：")]),_c('span',{staticClass:"content"},[_vm._v("---")])]),_c('div',{staticClass:"accounttext"},[_c('span',{staticClass:"accountlabel"},[_vm._v("最后登录时间：")]),_c('span',{staticClass:"content"},[_vm._v("----")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"item-icon"},[_c('span',[_c('i',{staticClass:"iconfont icon-guige"})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"notvertifiy"},[_c('span',[_vm._v("未实名认证")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"right-item"},[_c('p',{staticClass:"item-icon"},[_c('span',[_c('i',{staticClass:"iconfont icon-weixin"})])]),_c('p',{staticClass:"notvertifiy"},[_c('span',[_vm._v("未绑定微信")])]),_c('p',{staticClass:"vertifiy"},[_c('span',{staticClass:"vertifiyspan"},[_vm._v("去绑定")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"right-item itemold"},[_c('p',{staticClass:"item-icon"},[_c('span',[_c('i',{staticClass:"iconfont icon-qq"})])]),_c('p',{staticClass:"notvertifiy"},[_c('span',[_vm._v("未绑定QQ")])]),_c('p',{staticClass:"vertifiy"},[_c('span',{staticClass:"vertifiyspan"},[_vm._v("去绑定")])])])
}]

export { render, staticRenderFns }