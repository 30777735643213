<template>
  <div class="user-wrap">
    <div class="bread-wrap">
      <i class="iconfont icon-shouji"></i
      ><span class="bread-title">个人中心</span>
    </div>
    <div class="main-wrap">
      <div class="main-left">
        <div class="titleaccount">
          <i class="iconfont icon-gerenzhongxin"></i>
          <span class="account">195****1432</span>
        </div>
        <div class="accounttext">
          <span class="accountlabel">账号类型：</span>
          <span class="content">主账号</span>
        </div>
        <div class="accounttext">
          <span class="accountlabel">账号ID：</span>
          <span class="content">主账号</span>
        </div>
        <div class="accounttext">
          <span class="accountlabel">昵称：</span>
          <span class="content">---</span>
        </div>
        <div class="accounttext">
          <span class="accountlabel">性别：</span>
          <span class="content">---</span>
        </div>
        <div class="accounttext">
          <span class="accountlabel">最后登录时间：</span>
          <span class="content">----</span>
        </div>
      </div>
      <div class="main-right">
        <div class="mr-wrap">
          <div class="right-item itemold">
            <p class="item-icon">
              <span><i class="iconfont icon-guige"></i></span>
            </p>
            <!-- <p class="vertifiy" ><span>实名认证</span></p> -->
            <p class="notvertifiy"><span>未实名认证</span></p>
            <p class="vertifiy">
              <span @click="navauth" class="vertifiyspan">去认证</span>
            </p>
          </div>
          <div class="right-item">
            <p class="item-icon">
              <span><i class="iconfont icon-weixin"></i></span>
            </p>
            <!-- <p class="vertifiy" ><span>实名认证</span></p> -->
            <p class="notvertifiy"><span>未绑定微信</span></p>
            <p class="vertifiy"><span class="vertifiyspan">去绑定</span></p>
          </div>
          <div class="right-item itemold">
            <p class="item-icon">
              <span><i class="iconfont icon-qq"></i></span>
            </p>
            <!-- <p class="vertifiy" ><span>实名认证</span></p> -->
            <p class="notvertifiy"><span>未绑定QQ</span></p>
            <p class="vertifiy"><span class="vertifiyspan">去绑定</span></p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import navigation from "@/utils/navigation";
export default {
  data() {
    return {};
  },
  methods: {
    navauth() {
      navigation.navigateTo({
        url:'auth'
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "./index.scss";
</style>
